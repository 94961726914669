import {createRequest} from '~/utils/network-request'
import {Vue} from 'vue-property-decorator'
import {OrderStatus, PaymentMethod} from '~/components/data-class/data-class'
import {isEmpty} from '~/utils/misc'

export interface ImportProp {
    name: string
    required?: boolean
    readonly?: boolean
    converter?: (raw: string) => any
    validator?: (value: string) => boolean
}

export interface ImportValue {
    value: any
    valid: boolean
}

export interface ImportRow {
    values: ImportValue[]
    valid: boolean
    result?: string
}

export class ImportSetting {
    name = ''
    props: ImportProp[] = []
    data: ImportRow[] = []

    get header() {
        return this.props.map(p => p.required ? p.name + '*' : p.name).join('\t')
    }

    index(name: string) {
        return this.props.findIndex(p => p.name === name)
    }

    parse(str: string) {
        const cells = str.replace(/\r/g, '').split('\n')
            .map(row => {
                const splited = row.split('\t')
                const res: string[] = []
                let crr: string[] = []
                let f = false
                for (const v of splited) {
                    if (f) {
                        if (v.endsWith('"')) {
                            crr.push(v.slice(0, -1))
                            res.push(crr.join('\t'))
                            crr = []
                            f = false
                        } else {
                            crr.push(v)
                        }
                    } else {
                        if (v.startsWith('"') && !v.endsWith('"')) {
                            crr.push(v.replace('"', ''))
                            f = true
                        } else {
                            res.push(v)
                        }
                    }
                }
                return res
            })

        this.data = []
        for (const row of cells) {
            const invalid: string[] = []
            const rowData: ImportRow = {
                values: [],
                valid: true
            }
            for (let i = 0; i < this.props.length; i++) {
                const prop = this.props[i]
                const txt = row[i] || ''

                const value: ImportValue = {
                    value: prop.converter ? prop.converter(txt) : txt,
                    valid: prop.validator ? prop.validator(txt) : true
                }
                if (!value.valid)
                    invalid.push(prop.name)
                rowData.values.push(value)
                rowData.valid = rowData.valid && value.valid
            }
            if (invalid.length)
                rowData.result = `Invalid ${invalid.join(', ')}`
            this.data.push(rowData)
        }
        return this.data
    }

    startImport: () => Promise<any>
}

export class WaybillImport extends ImportSetting {
    name = 'Waybill'

    props = [
        {
            name: 'Order',
            required: true,
            validator: (str) => {
                return !isNaN(Number(str.replace('F', ''))) && str.length >= 8
            }
        },
        {
            name: 'Waybill #',
            required: true,
            validator: (str) => {
                if (!str.startsWith('SF'))
                    return false
                return !isNaN(Number(str.replace('SF', '')))
            }
        }
    ]

    startImport = async () => {
        const data: {
            order_id: string,
            waybill_number: string
        }[] = []
        for (const d of this.data) {
            if (!d.valid || d.result)
                continue
            data.push({order_id: d.values[0].value, waybill_number: d.values[1].value})
        }

        if (!data.length)
            return

        const res = await createRequest('/courses/orders/waybill-number',
            'post', {}, {data}).send()

        for (const d of this.data) {
            if (!d.valid || d.result)
                continue

            Vue.set(d, 'result', res.data.result[d.values[0].value])
            // d.result = res.data.result[d.values[0].value]
        }
    }
}

function classStringToId(raw: string) {
    const classStr = raw.toLowerCase().replace('class ', '')
    const charCode = classStr.charCodeAt(0)
    const code0 = '0'.charCodeAt(0)
    const code9 = '9'.charCodeAt(0)
    if (charCode >= code0 && charCode <= code9) {
        let num = ''
        for (let i = 0; i < classStr.length; i++) {
            if (classStr.charCodeAt(i) < code0 || classStr.charCodeAt(i) > code9)
                break
            num += classStr.charAt(i)
        }

        return Number(num)
    } else if (charCode >= 'A'.charCodeAt(0) && charCode <= 'Z'.charCodeAt(0)) {
        return charCode - 'A'.charCodeAt(0) + 1
    }
    return NaN
}

function parsePaymentMethod(raw) {
    if (!raw)
        return PaymentMethod.NOT_SELECTED

    const pmLookup = {
        '信用卡 (推薦，即時留位)': PaymentMethod.ONLINE_CARD,
        '信用卡 (即時留位)': PaymentMethod.ONLINE_CARD,
        '銀行轉帳': PaymentMethod.BANK_TRANSACTION,
        'FPS 轉數快': PaymentMethod.BANK_TRANSACTION,
        'Alipay HK': PaymentMethod.ALIPAY,
        '7-11入數': PaymentMethod.WECHAT,
        '現金': PaymentMethod.CASH,
    }
    if (!isEmpty(pmLookup[raw]))
        return pmLookup[raw]

    const res = PaymentMethod[raw]
    if (typeof res === 'number')
        return res
    if (typeof res === 'string')
        return PaymentMethod[res]

    return -2
}

function validMemberId(str: string) {
    return str.startsWith('MSID-') || str.startsWith('TMSID-')
}

enum OfflineStudentCellIndex {
    Name = 0,
    Phone,
    Email,
    Class,
    PaymentMethod,
    Price,
    Paid,
    SF,
    Waybill,
    MemberID,
    OrderID
}

export class OfflineStudentImport extends ImportSetting {
    name = 'Offline Students'
    courseId: string = ''

    props = [
        {
            name: 'Name',
            required: true
        },
        {
            name: 'Phone',
            required: true,
            validator: (str) => {
                return !isNaN(Number(str.replace('SF', '')))
            }
        },
        {
            name: 'Email',
            required: true
        },
        {
            name: 'Class',
            required: true,
            converter: classStringToId,
            validator: (raw) => {
                const v = classStringToId(raw)
                return v > 0
            }
        },
        {
            name: 'Payment Method',
            required: false,
            converter: parsePaymentMethod,
            validator: (raw) => {
                return parsePaymentMethod(raw) >= PaymentMethod.NOT_SELECTED
            }
        },
        {
            name: 'Price',
            required: true,
            converter: (raw) => {
                return Number(raw.replace('$', ''))
            },
            validator: (raw) => {
                return !isNaN(Number(raw.replace('$', '')))
            }
        },
        {
            name: 'Paid',
            required: false,
            converter: (raw) => {
                return !!raw ? 'Paid' : ''
            }
        },
        {
            name: 'SF Point',
            required: false,
            converter: (raw: string) => {
                return raw.split('\t')[0].split(' ')[0]
            }
        },
        {
            name: 'Waybill',
            required: false,
            validator: (raw: string) => {
                return !raw || raw.startsWith('SF')
            }
        },
        {
            name: 'Member',
            required: false
        },
        {
            name: 'Order',
            required: false,
            validator: (raw: string) => {
                return !raw || !isNaN(Number(raw))
            }
        }
    ]

    parse(str) {
        super.parse(str)

        const searchInput = this.data.map(row => {
            const cell = row.values[OfflineStudentCellIndex.MemberID]
            if (validMemberId(cell.value)) {
                return null
            }

            const email = row.values[OfflineStudentCellIndex.Email].value
            const phone = row.values[OfflineStudentCellIndex.Phone].value

            cell.value = 'Searching...'
            return {email, phone}
        })
        createRequest('/member-ids/search', 'post', {}, {data: searchInput}).send(true, false)
            .then(res => {
                for (const [i, result] of (res.data.member_ids as string[]).entries()) {
                    if (result)
                        this.data[i].values[OfflineStudentCellIndex.MemberID].value = result
                }
            })

        return this.data
    }

    startImport = async () => {
        if (!this.courseId) {
            return
        }

        for (const row of this.data) {
            if (!row.valid)
                continue

            function cell(colIndex) {
                return row.values[colIndex]
            }

            function cellText(colIndex) {
                return cell(colIndex).value
            }

            // Name	Phone	Email	Class	Method	Price	SF	Waybill	Member#	Order#
            const name = cellText(OfflineStudentCellIndex.Name)
            const phone = cellText(OfflineStudentCellIndex.Phone)
            const email = cellText(OfflineStudentCellIndex.Email)
            const classId = Number(cellText(OfflineStudentCellIndex.Class))
            const paymentMethod = Number(cellText(OfflineStudentCellIndex.PaymentMethod))
            const price = Number(cellText(OfflineStudentCellIndex.Price))
            const paid = !!cellText(OfflineStudentCellIndex.Paid)
            const sfLocation = cellText(OfflineStudentCellIndex.SF)
            const waybillNumber = cellText(OfflineStudentCellIndex.Waybill)
            const memberId = cellText(OfflineStudentCellIndex.MemberID)
            const orderId = cellText(OfflineStudentCellIndex.OrderID)

            if (orderId || !validMemberId(memberId))
                continue

            const status = paid ? (waybillNumber ? OrderStatus.MAILED : OrderStatus.PAID) : OrderStatus.PENDING

            const payload = {
                'member_id': memberId,
                'courses': [{'course_id': this.courseId, 'price': price}],
                payment_method: paymentMethod,
                'shipping_code': sfLocation,
                'phone': phone,
                'waybill_number': waybillNumber,
                'status': Number(status),
                'offline_data': {
                    [this.courseId]: {
                        'name': name,
                        'email': email,
                        'phone': phone,
                        'time_slot': Number(classId)
                    }
                }
            }

            Vue.set(row, 'result', 'Processing...')

            await createRequest(`/courses/order/member/${memberId}/force`, 'post', {}, payload)
                .send()
                .then((res) => {
                    if (res.data.order_id) {
                        cell(OfflineStudentCellIndex.OrderID).value = res.data.order_id
                        Vue.set(row, 'result', 'Success')
                    } else {
                        cell(OfflineStudentCellIndex.OrderID).value = res.data.old_order_ids[this.courseId] || ''
                        Vue.set(row, 'result', 'Already Enrolled')
                    }
                })
                .catch(() => {
                    Vue.set(row, 'result', 'Failed')
                })
        }
    }
}
